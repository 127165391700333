#root {
  background-image: url(./assets/mainbg.png) !important;
  background-size: cover;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #686868 #262626;
}


/* Works on Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #262626;
}

*::-webkit-scrollbar-thumb {
  background-color: #686868;
  border-radius: 20px;
  border: 3px solid #262626;
}

.shortcut-buttons-flatpickr-buttons {
  justify-content: center;
  width: 100%;
}

.shortcut-buttons-flatpickr-button {
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%);
  border-radius: 5px !important;
  height: 40px !important;
  color: #FFF !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 14px;
  width: 85%;
  border: 0;
  cursor: pointer;
  margin-bottom: 10px;
}